@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

* {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
}

a {
    text-decoration: none;
    display: inline-block;
    color: #44455c;
}

p {
    color: #929292;
    font-size: 15px;
}

input:focus,
select:focus {
    box-shadow: none !important;
    border-color: transparent;
}

input,
select {
    font-size: 15px;
    color: #929292;
}

.btn.pageBtn {
    display: inline-block;
    background: #c22131;
    color: #fff;
    font-size: 17px;
    padding: 8px 20px;
    border-radius: 5px;
    text-transform: capitalize;
    transition: .4s ease-in-out;
}

.btn.pageBtn:hover {
    background-color: #44455c;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #44455c;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.sectionPD {
    padding: 40px 0;
}

.sectionTitle h2 {
    font-size: 28px;
    margin-bottom: 24px;
}

.sectionTitle h2 span {
    color: #c22131;
    font-weight: normal;
}

.greyBg {
    background-color: #f6f6f6;
}

/* Header Start */
.navInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
}

nav.navbar.navbar-expand-lg.bg-body-tertiary {
    padding: 0;
}

.brand img {
    width: 65%;
}

.menus ul li a {
    padding: 19px 12px;
    color: #929292;
    transition: .4s ease-in-out;
    border-bottom: 2px solid #fff;
    font-size: 15px;
}

ul.navbar-nav.ms-auto.mb-2.mb-lg-0 {
    align-items: center;
}

.btn.registerBtn {
    background-color: #c22131;
    color: #fff;
    padding: 4px 15px;
    border: 2px solid #c22131;
}
.btn.registerBtn:hover{
    color: #c22131;
    background-color: #fff;
    border-color: #c22131;
}
.menus ul li a.active {
    color: #c22131;
    border-bottom: 2px solid #c22131;
}

.menus ul li a:hover {
    color: #c22131;

}

button#dropdown-basic {
    background-color: transparent;
    border: 0;
    padding: 0;
    margin-left: 10px;
}

button#dropdown-basic svg {
    color: #c22131;
    font-size: 22px;
}

.dropdownMenu .dropdown-item {
    font-size: 15px;
}

/* Header End */

/* Breadcrumb Start */
.pageBreadcrumb {
    background: url("https://hsalghanimdelivery.com/images/page-title-bg.jpg") no-repeat center / cover;
    padding: 10px 0;
}

.pageBreadcrumb .pageHeading {
    margin: 0;
    color: #e1e1e1;
    text-transform: capitalize;
    font-size: 20px;
    font-weight: normal;
}

.breadcrumbLabel {
    float: right;
}

.breadcrumbLabel .breadcrumb {
    margin: 0;
}

.breadcrumbLabel .breadcrumb-item a {
    color: #fff;
    font-size: 13px;
}

.breadcrumbLabel .breadcrumb-item.active {
    color: #fff;
    text-transform: capitalize;
}

/* Breadcrumb End */

/* Notice Board Start */
.noticeModal {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #252525f0;
    top: 0;
    z-index: 11;
}

.modal-dialog.noticeBoard {
    max-width: 800px;
    position: relative;
    border: 0;
    background: #fff;
    padding: 20px;
    margin-top: 50px;
}

.modal-dialog.noticeBoard .modal-content {
    border-radius: 0;
    border: 0;
}

.modal-dialog.noticeBoard {
    max-width: 800px;
    position: relative;
    border: 0;
}

.notice {
    margin-bottom: 25px;
}

#exampleModalLabel {
    color: #c22131;
}

.notice h4 {
    color: #324484;
}

.notice p {
    font-size: 15px;
    color: gray;
    margin-bottom: 5px;
}

.readMoreBtn {
    text-decoration: underline !important;

}
/* Notice Board End */

/* Preloader Start  */
.preloader{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 111;
    background-color: #fff;
}
/* Preloader End */

/* Back Top Start */
.BackTop{
    width: 40px;
    height: 40px;
    background-color: #c22131;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 11;
    cursor: pointer;
    color: #fff;
    font-size: 24px;
}
.BackTop svg{
    transform: rotate(270deg);
}
/* Back Top End */

/* Footer Start */
.footerSec {
    background-color: #f2f6fd;
}

.footerInfo h5 {
    margin-bottom: 15px;
}

.footerInfo .links li a {
    font-size: 15px;
    padding: 3px 0;
    color: #696969;
    transition: .4s ease-in-out;
}

.footerInfo .links li a:hover {
    color: #c22131;
}

.footerInfo .address li {
    display: flex;
    align-items: center;
    column-gap: 15px;
    margin-bottom: 15px;
}

.footerInfo .address .icon svg {
    font-size: 20px;
    color: #c22131;
}

.footerInfo .address .content {
    color: #696969;

}

.footerInfo .address .content p,
.footerInfo .address .content a {
    font-size: 14px;
}

.copyRight {
    background: #f2f6fd;
    border-top: 1px solid #ccd2de;
}

.copyRightInfo p {
    padding: 12px 0;
    margin: 0;
    font-size: 14px;
    text-align: center;
}

.copyRightInfo p a {
    color: #c22131;
}

.footerInfo p {
    font-size: 15px;
    color: #696969;
}

.readMore {
    color: #c22131;
    text-decoration: underline;
}

/* Footer End */

/* Search Section Start */
.searchSec {
    background: url("https://hsalghanimdelivery.com/images/search-bg.png") no-repeat center / cover;
}

.searchContent h3 {
    font-size: 40px;
    color: #000;
    font-weight: 900;
    margin-bottom: 40px;
}

.searchContent span {
    display: block;
    color: #c22131;
    font-weight: 700;
}

.searchBar {
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0px 20px 40px 5px rgb(0 0 0 / 10%);
}

.btn.searchBtn {
    background: #c22131;
    color: #fff;
    border: none;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    padding: 13px 20px;
    border-radius: 8px !important;
    margin-left: 10px;
}

.searchBar input {
    border: 0;
}

.searchBar select {
    border: 0;
    border-left: 1px solid #eee;
}

.popularInd {
    margin-top: 25px;
}

.popularInd h4 {
    font-size: 15px;
    margin-bottom: 10px;
}

.popularIndusList {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
}

.popularIndusList li a {
    background: #e7f1ff;
    color: #5e7ca1;
    font-size: 13px;
    padding: 5px 10px;
    border-radius: 25px;
    transition: .4s ease-in-out;
}

.popularIndusList li a:hover {
    background-color: #c22131;
    color: #fff;
}

.statsBox {
    margin-top: 20px;
}

.statsInfo {
    background: #fff;
    display: flex;
    align-items: center;
    column-gap: 20px;
    padding: 10px 20px;
    border-radius: 10px;
}

.statsIcon svg {
    font-size: 20px;
    color: #c22131;
}

.statsInfo h4 {
    font-size: 24px;
    margin: 0;
    font-weight: 500;
}

.statsText p {
    font-size: 14px;
    color: #929292;
}

.searchImg {
    animation: hero-thumb-animation 2s linear infinite alternate;
}

@keyframes hero-thumb-animation {
    0% {
        transform: translateY(-20px);
    }

    100% {
        transform: translateY(20px);
    }
}


/* Search Section End */

/* Featured Start */
.featuredCompany {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 25px;
    justify-content: start;
}

.featuredBox {
    width: 23%;
    border: 1px solid #eee;
    border-radius: 10px;
    transition: .4s ease-in-out;
}

.featuredBox a {
    width: 100%;
    height: 100%;
    padding: 22px 18px;
}

.featuredBox:hover {
    border-color: #c22131;
    transform: translateY(-3px);
    transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
    box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.1);
}

.comHeader {
    display: flex;
    justify-content: start;
    column-gap: 15px;
}

.comHeader .comLogo img {
    width: 45px;
    border-radius: 4px;
}

.comHeader .comText p {
    color: #c22131;
    margin: 0;
    font-size: 15px;
    text-transform: capitalize;
}

.comFooter {
    margin-top: 10px;
}

.brief {
    color: #696969;
    font-size: 15px;
    margin: 0;
    display: flex;
    align-items: center;
    column-gap: 7px;
}

/* Featured End */


/* Popular Item Start */
.srcHint .cateList {
    column-count: 3;
}

.cateList a {
    font-weight: 400;
    font-size: 15px;
    padding: 4px 0;
}

.cateList a span {
    color: #6ecb08;
}

/* Popular Item End */

/* Featured Jobs Start */
.featuredJobInfo {
    background-color: #fff;
    padding: 30px;
    border-radius: 15px;
    text-decoration: none;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    transition: all 0.4s ease 0s;
    position: relative;
    margin-bottom: 20px;
}

.featuredJobInfo:hover {
    transform: translateY(-3px);
    transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
    box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.1);
}

.featuredJobHeader {
    display: flex;
    column-gap: 13px;
    align-items: center;
    margin-bottom: 15px;
}

.featuredJobHeader .icon {
    width: 30px;
    height: 30px;
    background: #d0d5de;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}

.featuredJobHeader p {
    margin: 0;
    font-size: 14px;
}

.featuredJobBody {
    margin-bottom: 15px;
}

.featuredJobBody strong {
    color: #c22131;
}

.featuredJobFooter {
    display: flex;
}

.companyLogo {
    position: absolute;
    bottom: 20px;
    right: 20px;
}

.companyDetail p {
    color: #929292;
    font-size: 14px;
    margin: 0;
}

.companyLogo img {
    width: 65px;
}

/* Featured Jobs End */


/* Get Started Start */
.getStarted {
    background: url("https://hsalghanimdelivery.com/images/login-bg.jpg") no-repeat center / cover;
}

.getStart {
    text-align: center;
    width: 70%;
    margin: auto;
}

.workDetail {
    padding: 20px;
    text-align: center;
}

.workDetail svg {
    color: #c22131;
    font-size: 40px;
}

.workDetail h5 {
    margin: 20px 0 10px;
    font-weight: normal;
}

.workDetail p {
    color: #929292;
    font-size: 15px;
}

.getStart button.btn.pageBtn {
    background: #44455c;
}

.getStart button.btn.pageBtn:hover {
    background: #c22131;
}

/* Get Started End */


/* Latest Job Start */
.latestJobInfo {
    display: flex;
    align-items: start;
    justify-content: start;
    column-gap: 15px;
    background: #fff;
    padding: 15px;
    border-radius: 4px;
    margin-bottom: 15px;
}

.latestJobInfo .companyImg {
    width: 25%;
}

.companyText h6 {
    margin: 0;
}

.company {
    color: #999;
    padding: 7px 0;
    font-size: 13px;
}

.company a {
    color: #c22131;
}

.fulltime {
    background: #6ACA00;
    color: #fff;
    font-size: 12px;
    display: inline-block;
    padding: 3px 6px;
    border-radius: 3px;
}

.fulltime[title="Contract"] {
    background: #0C86F8;
}

.fulltime[title="Freelance"] {
    background: #ff0000;
}

.fulltime[title="Part Time"] {
    background: #f8a60c;
}

.fulltime[title="Internship"] {
    background: #9B54F4;
}

/* Latest Job End */



/* Testimonial Start */
.testimonial {
    background: url("https://hsalghanimdelivery.com/images/testimonials-bg.jpg") no-repeat center / cover;
}

.clientSays {
    background: #fff;
    padding: 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 8px;
}

.ratingUser ul {
    display: flex;
    margin-bottom: 10px;
}

.ratingUser ul li svg {
    color: #8ac64c;
}

.clientSays strong {
    color: #c22131;
    font-size: 15px;
    margin-bottom: 10px;
    display: block;
}

.clientSays p {
    color: #929292;
    font-size: 15px;
}

.testimonial .sectionTitle {
    text-align: center;
}

.testimonial .sectionTitle h2,
.testimonial .sectionTitle h5 {
    color: #fff;
}

/* Testimonial End */

/* Jobs Cities Start */
.cityInfoBox {
    margin-bottom: 20px;
    transition: .4s ease-in-out;
}

.cityInfoBox:hover {
    transform: translateY(-3px);
    transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
    box-shadow: 0px 10px 20px -5px rgba(0, 0, 0, 0.1);
}

.cityImg img {
    border-radius: 8px 8px 0 0;
}

.cityText {
    background-color: #fff;
    padding: 15px;
    border-radius: 0 0 8px 8px;
}

.cityText h4 a {
    color: #c22131;
    font-size: 18px;
}

.cityText span {
    font-size: 14px;
}

/* Jobs Cities End */


/* Subscribe Start */
.subscribe {
    padding: 70px 50px;
    background: #c22131 url("https://hsalghanimdelivery.com/images/newsletter-bg.png") no-repeat center / cover;
    text-align: center;
    border-radius: 30px;
}

.subsForm input {
    border: 0;
}

.subsForm input:focus[name="name"] {
    border-right: 1px solid gray;
}

.subsForm input[name="email"] {
    border-left: 1px solid gray;
}

.subsForm input:focus[name="email"] {
    border-left: 1px solid gray;
}

/* Subscribe End */

/* Latest Blog Start */
.postContent {
    padding: 14px;
}

.postHeader h4 {
    font-size: 20px;
}

.postMeta a {
    font-size: 14px;
    color: #8ac64c;
}

.postMeta {
    margin-bottom: 10px;
}

.postHeader p {
    font-size: 15px;
    color: #929292;
}

/* Latest Blog End */



/** Jobs Page CSS Start **/
.pageSearch {
    padding: 50px;
    background: #f2f6fd url("https://hsalghanimdelivery.com/images/int-searchbg.jpg") no-repeat center / cover;
    position: relative;
}

.searchForm .input-group {
    padding: 10px;
    background: #fff;
    border-radius: 10px;
    width: 85%;
}

.btn.searchBtn {
    padding: 4px 10px;
    font-size: 24px;
}

.searchForm .input-group input {
    border: 0;
}

.topStatInfo {
    margin-bottom: 10px;
}

.featuredJob {
    padding: 20px;
    background: #f7f5ff;
    margin-bottom: 20px;
    border: 1px solid #f2f2f2;
}

.featuredDetail {
    display: flex;
    align-items: center;
    column-gap: 20px;
}

.jobImg img {
    width: 100px;
}

.jobPara p {
    margin: 20px 0 0;
    font-size: 15px;
    color: #929292;
}

.featuredJobText h4 {
    margin: 0;
}

.featuredJobText h5 svg {
    font-size: 17px;
    color: #c22131;
    margin-left: 5px;
}

.featuredJobText .companyName a {
    color: #a3a3a3;
    font-size: 14px;
    margin-bottom: 8px;
}

/** Jobs Page CSS End **/


/** Aside CSS Start **/
.jobReqBtn {
    margin-bottom: 20px;
}

.jobReqBtn .btn-job-alert {
    color: #999 !important;
    border: 1px solid #999;
    cursor: pointer;
    background: none;
    display: block;
    margin-bottom: 5px;
    padding: 10px 0;
}

.jobReqBtn .btn.inputFile {
    display: block;
    padding: 10px 20px;
    text-align: left;
    margin-bottom: 10px;
    background: #c22131;
    color: #fff;
}

.jobReqBtn a {
    display: block;
    padding: 10px 20px;
    text-align: left;
    margin-bottom: 10px;
    background: #c22131;
    color: #fff;
}

.sidebar {
    background: #f2f6fd;
    padding: 20px;
    margin-bottom: 30px;
    border-radius: 5px;
}

.sidebar .widget {
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
}

.sidebar .widget-title {
    font-size: 14px;
    font-weight: 600;
    color: #000;
    margin-bottom: 20px;
}

.optionList li input {
    margin-right: 10px;
}

.optionList li {
    margin-bottom: 10px;
    padding: 5px 0;
    position: relative;
    font-size: 14px;
}

.optionList li span {
    position: absolute;
    right: 0;
    top: 6px;
}

.hide_vm_ul {
    height: 120px;
    overflow: hidden;
}

.location label.fulltime {
    margin-right: 10px;
}

.location span {
    font-size: 14px;
}

button.btn.view_more {
    color: #0C86F8;
    padding: 0;
    font-size: 13px;
    text-decoration: underline;
}

/** Aside CSS End **/



/** Login Page Start  **/
.login {
    padding-top: 10px;
    background: url(https://hsalghanimdelivery.com/images/search-bg.png) no-repeat center / cover;
}

.userLogin {
    background-color: #fff;
    padding: 35px;
    border-bottom: 3px solid #c22131;
    box-shadow: 0px 6px 12px rgb(0 0 0 / 5%);
}

.socialLoginBox {
    text-align: center;
    margin-bottom: 20px;
}

.socialIcons {
    display: flex;
    align-items: center;
    column-gap: 10px;
    justify-content: center;
}

.socialIcons a img {
    width: 40px;
}

.loginDivider {
    text-align: center;
    position: relative;
    margin-bottom: 30px;
}

.loginDivider:before {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 1px;
    content: "";
    background: lightgrey;
}

.loginDivider span {
    color: #44455c;
    background-color: #fff;
    padding: 3px 5px;
    position: relative;
    z-index: 1;
    font-size: 13px;
    text-transform: uppercase;
}

.loginForm input.form-control {
    border-radius: 0;
    font-size: 14px;
    padding: 8px 12px;
}

.forgotPass span {
    font-size: 15px;
    display: inline-block;
    margin-right: 8px;
}

.forgotPass a {
    font-size: 15px;
    color: #c22131;
}

.loginInfo .nav-item #uncontrolled-tab-example-tab-candidate {
    background: #c22131;
    color: #fff;
    border-radius: 0;
}

.loginInfo .nav-item #uncontrolled-tab-example-tab-employer {
    background-color: #fff;
    color: #c22131;
    border-radius: 0;
    border-color: #fff;
}
.loginInfo .nav-item {
    width: 50%;
}

.loginInfo .nav-item button {
    width: 100%;
}
/** Login Page End  **/


/** Register Page Start  **/
.checkField span {
    font-size: 13px;
    display: inline-block;
    margin-left: 8px;
}
.checkField {
    margin-bottom: 5px;
}
/** Register Page End  **/


/** About Page Start  **/
.aboutInfo h1 {
    font-size: 30px;
}

.aboutInfo p {
    color: #929292;
    font-size: 15px;
}

/** About Page End  **/

/** Contact Page Start  **/
.contactAddress {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 20px;
}

.contactAdd {
    width: 100%;
    padding: 15px;
    background: #f6f6f6;
}

.contactAdd .information {
    margin-top: 15px;
}

.contactTitle span {
    font-size: 13px;
    color: #44455c;
}

.contactAdd .information strong {
    color: #44455c;
    margin-bottom: 5px;
    display: block;
}

.contactAdd .icon svg {
    color: #c22131;
}

.contactAdd .information p {
    margin-bottom: 5px;
}

.contactAdd .information p a {
    color: #c22131;
}

.contactAdd .information.number p a {
    color: #44455c;
    font-size: 14px;
    transition: .4s ease-in-out;
}

.contactAdd .information.number p a:hover {
    color: #c22131;
}

.contactFormSec {
    margin-top: 20px;
}

.contactField label {
    font-size: 15px;
    margin-bottom: 5px;
    font-weight: 600;
    color: #44455c;
}

.contactField input,
.contactField textarea {
    border-radius: 0;
    font-size: 15px;
}

.contactField input {
    height: 44px;
}

/** Contact Page End  **/

/** Job Detail Page Start  **/
.jobPageTitle {
    padding-top: 20px;
}

.jobPageInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.jobPageInfo .jobTitle a {
    color: #c22131;
    font-size: 14px;
}

.jobPageInfo .jobTitle .text {
    color: #929292;
    font-size: 14px;
}

.btn.pageBtn.apply {
    background-color: #62a518;
}

.jbDetailBox {

    border: 1px solid #ebebeb;
    margin-bottom: 30px;
}

.jbDetailHeader {
    padding: 25px;
}

.jbDetailHeader h5 {
    margin-bottom: 15px;
    color: #c22131;
    display: flex;
    align-items: center;
    column-gap: 8px;
    font-size: 18px;
}

.jbDetailHeader h5 svg {
    color: #b6b6b6;
}

.jbDetailHeader .jbDetailList li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.jbDetailHeader .jbDetailList .property h6 {
    margin: 0;
    font-weight: normal;
    color: #929292;
    font-size: 15px;
}

.jbDetailHeader .jbDetailList .property {
    width: 200px;
}

.jbDetailHeader .jbDetailList .value a {
    display: block;
    color: #c22131;
    font-weight: 600;
    font-size: 15px;

}

.jbDetailHeader .jbDetailList .value span {
    color: #44455c;
    font-size: 15px;
    font-weight: 600;
}

.jbDetailHeader .jbDetailList .value span.permanent {
    color: #c22131;
}

.jbDetailHeader .jbDetailList .value span.freelance {
    color: #F07200;
}

.jbDetailHeader.benefits ul li {
    font-size: 15px;
    color: #929292;
    line-height: 30px;
}

.jbDetailHeader.benefits ul li svg {
    margin-right: 10px;
    color: #c22131;
}

.jbDetailHeader.skillsRequired ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: start;
}

.jbDetailHeader.skillsRequired ul li a {
    padding: 8px 18px;
    background: #dbdbdb;
    border-radius: 50px;
    color: #44455c;
    font-size: 15px;
    transition: .4s ease-in-out;
}

.jbDetailHeader.skillsRequired ul li a:hover {
    background-color: #62a518;
    color: #fff;
}

.jbButtons {
    display: flex;
    align-items: center;
    justify-content: start;
    column-gap: 10px;
    padding: 25px;
    margin-top: 20px;
    border-top: 1px solid #ebebeb;
}

.jbButtons .btn {
    background: #fff;
    border: 1px solid #c22131;
    border-radius: 20px;
    color: #c22131;
    font-size: 15px;
    transition: .4s ease-in-out;
}

.jbButtons .btn:hover {
    color: #fff;
    background-color: #c22131;
    border-color: #c22131;
}

.companyHeader {
    display: flex;
    justify-content: start;
    column-gap: 10px;
}

.companyHeader .logo {
    width: 100px;
}

.companyHeader .content .companyName {
    font-size: 19px;
}

.companyHeader .content .location {
    color: #c22131;
    font-size: 14px;
    font-weight: normal;
}

.companyHeader .content .companyName {
    font-size: 19px;
}

.companyHeader .content .location {
    color: #62a518;
    font-size: 14px;
    font-weight: normal;
}

.openingText {
    margin: 10px 0 20px;
    background: #ebebeb;
    padding: 10px;
}

.openingText a {
    color: #44455c;
    font-size: 14px;
    font-weight: 500;
}

.relatedJobInfo {
    padding: 15px;
    background: #f7f5ff;
    margin-bottom: 20px;
    border: 1px solid #f2f2f2;
}

.partTime {
    background: #f8a60c;
    color: #fff;
    font-size: 12px;
    display: inline-block;
    padding: 3px 6px;
    border-radius: 3px;
}

.relatedJobInfo .companyName a {
    color: #929292;
    font-size: 14px;
}

.jbDetailHeader.company {
    padding: 15px;
    background-color: #f7f5ff;
}

/** Job Detail Page End  **/


/** Blog Page Start  **/
.blogInfo.blogInner {
    margin-bottom: 20px;
}

.blogAsideBox {
    padding: 15px;
    background-color: #f7f5ff;
}

.blogAsideBox {
    padding: 15px;
    background-color: #f7f5ff;
}

.searchBlog input {
    border-radius: 0;
}

.searchBlog {
    position: relative;
}

.searchBlog button {
    position: absolute;
    right: 0;
    top: 0;
}
.blogAsideBox .categoryList li a{
    font-size: 15px;
    padding: 3px 0;
}
/** Blog Page End  **/

/** Company Detail Page Start  **/
.candidateSec{
    border: 1px solid #ebebeb;
    padding: 20px;
}
.candidateInfo {
    display: flex;
    align-items: center;
    justify-content: start;
    column-gap: 15px;
}
.candidateInfo .userPic{
    width: 120px;
    height: 120px;
}
.localTime ul li {
    display: flex;
    align-items: center;
    column-gap: 6px;
    font-size: 14px;
    line-height: 27px;
}
.localTime ul li svg {
    color: #c22131;
}
.candidateInfo .userText .desc {
    margin-bottom: 6px;
}
.candidateLogin {
    float: right;
    text-align: right;
}
.companyLeftBox{
    border: 1px solid #ebebeb;
    margin-bottom: 30px;
}
.companyInnerBox{
    padding: 25px;
}
.companyInnerBox{
    margin-bottom: 15px;
    color: #c22131;
    font-size: 18px;
}
.partTime[title="Second Shift (Afternoon)"] {
    background: #688CD5;
}

.jbDetailHeader.company{
    padding: 15px;
    background: #f7f5ff;
    margin-bottom: 20px;
    border: 1px solid #f2f2f2;
}
/** Company Detail Page End  **/