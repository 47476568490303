@media (max-width: 992px) {
    .modal-dialog.noticeBoard {
        max-width: 90%;
        margin:40px auto;
    }

    .menus ul {
        margin-top: 10px;
    }

    .menus ul li {
        display: block;
        width: 100%;
    }

    .menus ul li a {
        border-bottom: 1px dotted #e6e6e6;
        display: block;
    }

    .btn.registerBtn {
        margin: 10px 0;
    }

    .menus ul li.active a {
        display: block;
    }

    button.navbar-toggler {
        padding: 0;
        border: 0;
    }

    button.navbar-toggler:focus {
        box-shadow: none;
    }

    ul.navbar-nav.ms-auto.mb-2.mb-lg-0 {
        align-items: start;
    }
    .clientSays {
        margin-bottom: 20px;
    }

    .featuredCompany {
        gap: 30px;
        justify-content: space-between;
    }
    .featuredBox {
        width: 30%;
    }
    .cityImg img{
        width: 100%;
    }
}

@media (max-width: 767px) {
    .featuredBox {
        width: 47%;
    }

   
    .statsInfo{
        margin-bottom: 20px;
    }
    .srcHint .cateList {
        column-count: 2;
    }
    .embed-responsive.embed-responsive-16by9 {
        display: flex;
        justify-content: center;
    }
}

@media (max-width: 460px) {
    .featuredBox {
        width: 100%;
    }
    .srcHint .cateList {
        column-count: 1;
    }
    .jobVideoText {
        text-align: center;
    }
}